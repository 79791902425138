export default function download(filename, data, dataType){
    var a = document.createElement("a");
    //document.body.appendChild(a);
    a.style = "display: none";
    const file = new Blob(
    [data],
    {type: dataType});
    const fileURL = URL.createObjectURL(file);
    a.href = fileURL;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(fileURL);
}