import axios from "axios";
import { SET_ERROR } from "./errorReducer";
import Swal from "sweetalert2";
import { SubmissionError } from "redux-form";
import { objToQuery } from "../front-utils/lib";
export const reducerName="DDJJVertiente";
export default function (state = INITIAL_STATE, action){
    if (reducerName!=action.type) return state;
    const newstate=action.fn(state,action);
    if (newstate)return newstate;
    return {...state}; console.log(newstate)
}
const INITIAL_STATE = {
    ddjjs:[],
    state:{filters:{}}
};
//ESTE DEBE SER EL REDUCER DE ACTIVIDADES
const api="/services/tramites/api/contaminacion/"
export const  guardarDDJJActividad= (ddjj) => async (dispatch) => {
    try {
        const ddjjActividadesDTO={"ddjjActividadesDTO":ddjj}
         await axios.put( api + "guardarFormulario/" ,
         ddjjActividadesDTO , {params:{tramiteId: ddjj.tramite.id}});
    }
    catch(error){
        console.error(error)
        if (error.response){ 
            dispatch({ type: SET_ERROR, payload: error.response });
        }
        throw error;
   }
    
    
}
export const  confirmarDDJJActividad= (ddjj) => async (dispatch) => {
    try {
        const ddjjActividadesDTO={"ddjjActividadesDTO":ddjj}
         await axios.put( api + "confirmarFormulario/" ,
         ddjjActividadesDTO , {params:{tramiteId: ddjj.tramite.id}});
    }
    catch(error){
        console.error(error)
        if (error.response){ 
            dispatch({ type: SET_ERROR, payload: error.response });
        }
        throw error;
   }
    
    
}

