import axios from "axios";
import { SET_ERROR, SET_SUCCESS_LIGHT } from "./errorReducer";
import Swal from "sweetalert2";
import { SubmissionError } from "redux-form";
import { objToQuery } from "../front-utils/lib";
export const reducerName="fileInputReducer";

export default function (state = INITIAL_STATE, action){
    if (reducerName!=action.type) return state;
    const newstate=action.fn(state,action);
    if (newstate)return newstate;
    return {...state};
}
const INITIAL_STATE = {
    totalPages:0,
    currentPage:0,
    archivoSelected: null,
    archivoDeleted:"",
};

const apiUrl = "/services/tramites/api/tramites";
export const cargarArchivoTramite = (id, archivo) => async (dispatch) => {
    try {
        const result = await axios.post(apiUrl + "/cargarArchivo/" + id, archivo);
        dispatch({type: SET_SUCCESS_LIGHT,payload:{title:"Exito!",detail:"Se ha cargado con exito"}})
        return result.data; 
    } 
    catch(error){
        console.error(error)
        if (error.response){ 
            dispatch({ type: SET_ERROR, payload: error.response });
        }
        throw error;
    }
};

export const borrarArchivoTramite = (id,archivo) => async (dispatch) => {
    
    
    try {
        
        const result = await axios.post(apiUrl + "/quitarArchivo/"+ archivo.id + "/" + id,);
        dispatch({type: SET_SUCCESS_LIGHT,payload:{title:"Exito!",detail:"Se ha borrado exito"}})
        dispatch({ 
            type: reducerName,
                payload:result,
                fn: (state,action) => ({
                    ...state,
                    ...action.payload
                })

        });
    } 
    catch(error){
        console.error(error)
        if (error.response){ 
            dispatch({ type: SET_ERROR, payload: error.response });
        }
        throw error;
    }
}




