import axios from "axios";
import { SET_ERROR } from './errorReducer';


export const ACTION_TYPES = {
  BUSCAR_USUARIO: 'BUSCAR_USUARIOS',
  BUSCAR_USER: 'BUSCAR_USER',
  SET_LEGAJO: 'SET_LEGAJO',
  SET_NOMBRE: 'SET_NOMBRE',
  SET_FOTO: 'SET_FOTO',
  SET_USER: 'SET_USER',
  SET_PERMISOS: 'SET_PERMISOS',
  SET_ORGANISMO: "SET_ORGANISMO_EN_USER", 
  COORDINAR_USUARIO:"ACTION_TYPES.COORDINAR_USUARIO",
  SET_PERSONA_USUARIOPORTAL:"SET_PERSONA_USUARIOPORTAL"
};

const initialState = {
  usuario: {},
  cargando: false,
  nombre: null,
  foto: null,
  user: null,
  permisos: null,
  persona:null,
  errores: {},
  organismo:null,
};

// Reducer

export default function (state = initialState, action) {
  switch (action.type) {


    case ACTION_TYPES.SET_NOMBRE: {
      return {

        ...state,
        nombre: action.payload,
      };
    }
    case ACTION_TYPES.SET_FOTO: {
      return {

        ...state,
        foto: action.payload,
      };
    }

    case ACTION_TYPES.SET_USER: {
      return {

        ...state,
        user: action.payload,
      };
    }

    case ACTION_TYPES.SET_PERMISOS: {
      return {

        ...state,
        permisos: action.payload,
      };
    }
    case ACTION_TYPES.SET_ORGANISMO: {
      return {
        ...state,
        organismo: action.payload,
      };
    }
    case ACTION_TYPES.COORDINAR_USUARIO: {
      return {
        ...state,
        usuario: action.payload.data,
      }
    }

    case ACTION_TYPES.SET_PERSONA_USUARIOPORTAL: {
      return {
        ...state,
        persona: action.payload.data[0],
      }
    }
    default:
      return state;
  }
};




export const setUser = (user) => async dispatch => {


  try {
    dispatch({ type: ACTION_TYPES.SET_NOMBRE, payload: user.name && user.name });
    dispatch({ type: ACTION_TYPES.SET_FOTO, payload: user.foto && user.foto});
    dispatch({ type: ACTION_TYPES.SET_USER, payload: user });
    dispatch({ type: ACTION_TYPES.SET_PERMISOS, payload: user.groups });
    dispatch({ type: ACTION_TYPES.SET_ORGANISMO, payload: localStorage.organismo*1});
    localStorage.setItem("grupos-permisos", user.groups && user.groups);
  }
  catch(e){
    console.error(e);
  }
    
    

  

};
export const actualizarOrganismo = () => async dispatch => {


  try {

    dispatch({ type: ACTION_TYPES.SET_ORGANISMO, payload: localStorage.organismo*1 || undefined});

  } catch (error) {
    dispatch({ type: SET_ERROR, payload: error.response });
  }

};


const apiUrl = "/services/serviciosweb/api/usuario-portals";

// Actions
const apiUrl2 = "/services/presupuesto/api/private-usuario"


export const coordinarUsuario = () => async (dispatch) => {
  try {
    
    let res = await axios.post(apiUrl + '/coordinar');
    const codigoPersona=res.data.codigoPersona
    dispatch({ type: ACTION_TYPES.COORDINAR_USUARIO, payload: res });
    if (res.data.codigoPersona){
      res=await axios.get(apiUrl2+"/personas",{params:{"codigo.equals":res.data.codigoPersona}});
      dispatch({ type: ACTION_TYPES.SET_PERSONA_USUARIOPORTAL, payload: res });
    }
    return codigoPersona
  } catch (error) {
    throw new Error(error);
  }
};




