import { combineReducers } from 'redux';
import { reducer as reduxForm } from 'redux-form';
import actividadReducer from './actividadReducer';
import errorReducer from './errorReducer';
import insumoReducer from './insumoReducer';
import materiaReducer from './materiaReducer';
import operacionReducer from './operacionReducer';
import productoReducer from './productoReducer';
import servicioReducer from './servicioReducer';
import sustanciaAlternativaReducer from './sustanciaAlternativaReducer';
import userReducer from './userReducer';
import tramitesReducer from './tramitesReducer';
import tiposTramitesReducer from './tiposTramitesReducer';
import departamentoReducer from './departamentoReducer';
import distritoReducer from './distritoReducer';
import tipoDeCultivoReducer from './tipoDeCultivoReducer';
import datosVuelcoList from './datosComplementariosVuelcoListReducer'
import unidadMedida from './unidadDeMedidaReducer';
import fileInputReducer from './fileInputReducer'; 
import vertienteReducer from './DDJJVertiente'

export default combineReducers({
  form: reduxForm,
  tiposTramitesReducer,
  errorReducer,
  userReducer,
  servicioReducer,
  actividadReducer,
  materiaReducer,
  insumoReducer,
  productoReducer,
  sustanciaAlternativaReducer,
  operacionReducer,
  tramitesReducer,
  departamentoReducer,
  distritoReducer,
  tipoDeCultivoReducer,
  datosVuelcoList,
  unidadMedida,
  fileInputReducer,
  vertienteReducer,
});