import axios from "axios";
import { SET_ERROR } from "./errorReducer";
import Swal from "sweetalert2";
import { SubmissionError } from "redux-form";
import { objToQuery } from "../front-utils/lib";
export const reducerName="productoReducer";
export default function (state = INITIAL_STATE, action){
    if (reducerName!=action.type) return state;
    const newstate=action.fn(state,action);
    if (newstate)return newstate;
    return {...state};
}
const INITIAL_STATE = {
    totalPages:0,
    currentPage:0,
    productos:null,
};
const example=undefined
const apiUrl = "/services/servicioshidricos/api";
export const buscarProductos = (page,size,searchProps,withOutDispatch) => async (dispatch) => {
    const obj = objToQuery({
        page: page,
        size: size,
        ...searchProps,
    });
    try {
        const res=await axios.get(`${apiUrl}/producto-terminados${obj}`)
        let cantTotal = parseInt(res.headers["x-total-count"]);
        const result={
            productos:example || res.data,
            currentPage: page,
            totalPages: Math.ceil(cantTotal / size),
        };
        if (withOutDispatch){
            return result
        }
        else {
            dispatch({
                type: reducerName,
                payload:result,
                fn: (state,action) => ({
                    ...state,
                    ...action.payload
                })
            })   
        }

    }  
    catch(error){
        console.error(error)
        if (error.response){ 
            dispatch({ type: SET_ERROR, payload: error.response });
        }
        throw error;
    }
}

