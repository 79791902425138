import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable';
// import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';

import reducers from './app/reducers';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import keycloak from './keycloak';
const middleware = composeWithDevTools(applyMiddleware(reduxThunk));
const store = createStore(reducers, middleware);
window.axios= axios;
axios.interceptors.response.use((response)=>{
  
  return response;
}, (error)=>{
  return Promise.reject(error);
})
axios.interceptors.request.use(
    
    config => {
        keycloak.updateToken(1000).then((refreshed) => {
            
            if (refreshed) {
                localStorage.setItem("react-token", keycloak.token);
                localStorage.setItem("react-refresh-token", keycloak.refreshToken);
                console.debug('Token refreshed' + refreshed);
            } else {
                console.warn('Token not refreshed, valid for '
                    + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
            }
        }).catch(() => {
            console.error('Failed to refresh token');
        });
        const token = keycloak.token;
        //console.log(token)
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        // config.headers['Content-Type'] = 'application/json';
        
        config.url=config.url.replace("/cache-server/","http://test.di.dgi.loc:9081/cache-server/");
        return config;
  }, error => {
    // handle the error
    return Promise.reject(error);
  });




ReactDOM.render(
    <Provider store={store}>
      <App />,
    </Provider>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
