import React,{useState} from 'react';
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import keycloak from './keycloak';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { setUser ,coordinarUsuario} from './app/reducers/userReducer';
import { useDispatch } from "react-redux";
import { setServicio} from "src/app/reducers/servicioReducer.js"


import LoadingOverlay from 'react-loading-overlay';
import Swal from 'sweetalert2';
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout/DefaultLayout'));
const takeOut=()=>{
  let urlTramites;
  if (process.env.NODE_ENV != "production"){
    urlTramites=window.location.protocol+"//"+window.location.hostname+":3017";
  }
  else {
    const hostnames=window.location.hostname.split(".");
    for (const name of  hostnames){
        hostnames.shift();
        if (name=="tramites"){
            break;
        }
    }
    urlTramites=window.location.protocol+"//portalweb."+hostnames.join(".")+"";
  }
  window.location.href=(urlTramites)
}

export const App = () => {
  
  const dispatch = useDispatch();
  const [cargando, setCargando] = useState(true)
  const eventLogger = (event, error) => {
    if (typeof error !== undefined) {
      switch (event) {
        case 'onAuthSuccess':
          keycloak.loadUserInfo().then((data) => {
                  
                  /*const grupos = keycloak && keycloak.tokenParsed && keycloak.tokenParsed.groups
            
                  
                  const { legajo, name, foto } = keycloak && keycloak.tokenParsed && keycloak.tokenParsed
            
                  localStorage.setItem("grupos-permisos", grupos);*/
                  //localStorage.clear();
                  dispatch(setUser(data))
           

                  dispatch(coordinarUsuario())
                  .then((codigoPersona) => {
                    if (!codigoPersona){
                      takeOut();
                    }
                    setCargando(false);
                  }  
                  )
                  .catch(e => {
                    console.error(e)
                    Swal.fire("Ud no tiene permisos para ingresar a tramites", "error").then(e =>false && takeOut())
                  }
                  );
            }
          )
          break;
        case 'onAuthLogout':
          break;
        default:
          break;
      }
    }
  }

  let initOptions = {
   
    onLoad: 'login-required',
    checkLoginIframe : false,
 }
    return (
      <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={initOptions}
        onEvent={eventLogger}
      >
        <LoadingOverlay
            active={cargando}
            spinner
          text='Cargando contenido...'
        >
          {!cargando && 
          <BrowserRouter>
            <React.Suspense fallback={loading()}>
              <Switch>
                  <Route path="/" name="Inicio" render={props => <DefaultLayout {...props} />} />
                
              </Switch>
            </React.Suspense>
          </BrowserRouter>
          }
        </LoadingOverlay>
      </ReactKeycloakProvider>
    );
}

export default App;

