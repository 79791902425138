import axios from "axios";
import { SET_ERROR, SET_ERROR_LIGHT } from "./errorReducer";
import Swal from "sweetalert2";
import { SubmissionError } from "redux-form";
import { objToQuery } from "../front-utils/lib";
import download from "../front-utils/makeFileToDownload";
export const reducerName="tramitesReducer";
export default function (state = INITIAL_STATE, action){
    if (reducerName!=action.type) return state;
    const newstate=action.fn(state,action);
    if (newstate)return newstate;
    return {...state};
}
const INITIAL_STATE = {
    tramites:[],
    state:{filters:{}}
};
const api="/services/tramites/api"

export const buscarTramites = (page,size,searchProps,withOutDispatch) => async (dispatch) => {
    const obj = objToQuery({
        page: page,
        size: size,
        ...searchProps,
    });
    try {
        const res=await axios.get(`${api}/tramites/${obj}`)
        let cantTotal = parseInt(res.headers["x-total-count"]);
        const result={
            tramites: res.data,
            currentPage: page,
            totalPages: Math.ceil(cantTotal / size),
        };
        if (withOutDispatch){
            return result
        }
        else {
            dispatch({
                type: reducerName,
                payload:result,
                fn: (state,action) => ({
                    ...state,
                    ...action.payload
                })
            })   
        }
    }  
    catch(error){
        console.error(error)
        if (error.response){ 
            dispatch({ type: SET_ERROR, payload: error.response });
        }
        throw error;
    }
}


export const nuevoTramite = (data) => async (dispatch) => {
    try {
        const res=await axios.post(`${api}/tramites/crearTramite`,data)
        return res.data;
    }
    catch(error){
        console.error(error)
        if (error.response){ 
            dispatch({ type: SET_ERROR, payload: error.response });
        }
        throw error;
    }
}
export const getTramite = (id) => async (dispatch) => {
    try {
        const res=await axios.get(`${api}/contaminacion/tramiteDto/?idTramite=${id}`)
        return res.data;
    }
    catch(error){
        console.error(error)
        if (error.response){ 
            dispatch({ type: SET_ERROR, payload: error.response });
        }
        throw error;
    }
}
export const getTramiteSinDto = (id) => async (dispatch) => {
    try {
        const res=await axios.get(`${api}/tramites/${id}`)
        return res.data;
    }
    catch(error){
        console.error(error)
        if (error.response){ 
            dispatch({ type: SET_ERROR, payload: error.response });
        }
        throw error;
    }
}
export const getTramitePDFBlob = (data) => async (dispatch) => {
    try {
        const res=await axios.get(`${api}/tramites/generarPDF/${data.id}`,{responseType: 'blob'});
        const file = new Blob(
            [res.data],
            {type: "application/pdf"});
        return file;
    }
    catch(error){
        console.error(error)
        if (error.response){ 
            dispatch({ type: SET_ERROR, payload: error.response });
        }
        throw error;
    }
}
export const getTramitePDF = (data) => async (dispatch) => {
    try {
        const res=await axios.get(`${api}/tramites/generarPDF/${data.id}`,{responseType: 'blob'});
        download("Tramite-"+data.id+".pdf",res.data,"pdf")
        console.log(res)
    }
    catch(error){
        console.error(error)
        if (error.response){ 
            dispatch({ type: SET_ERROR, payload: error.response });
        }
        throw error;
    }
}
export const getArchivoPDF = (data) => async (dispatch) => {
    try {
        console.log(data);
        const idArchivo = data?.archivoTramites?.at(0);        
        const res=await axios.get(`${api}/archivo-tramites/descargarArchivo/${idArchivo.id}`,{responseType: 'blob'});
        download("Archivo-"+idArchivo.nombre,res.data,"pdf")
        
    }
    catch(error){
        console.error(error)
        if (error.response){ 
            dispatch({ type: SET_ERROR, payload: error.response });
        }
        throw error;
    }
}



